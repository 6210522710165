import {DateTime} from "luxon"
import parser from "any-date-parser"

export const defaultMonths = {
    1: "Leden",
    2: "Únor",
    3: "Březen",
    4: "Duben",
    5: "Květen",
    6: "Červen",
    7: "Červenec",
    8: "Srpen",
    9: "Září",
    10: "Říjen",
    11: "Listopad",
    12: "Prosinec",
}

export const defaultDays = [
    "Po",
    "Út",
    "St",
    "Čt",
    "Pá",
    "So",
    "Ne",
]

export type SelectedDates = {
    selectedFrom: DateTime
    selectedTo: DateTime
    click: boolean
    enter: boolean
}

export type CalendarEvent = {
    id: number | string
    tooltip: string | null
    color: string
}

export const parseDate = (string: string): DateTime | null => {
    const date = parser.attempt(string)
    if (date.invalid) {
        let day = parseInt(string)
        if(isNaN(day) || day < 1) {
            return null
        }

        const now = DateTime.now()
        if(now.daysInMonth < day) {
            return null
        }

        return now.set({day})
    }

    return DateTime.fromObject(date)
}